import React from 'react';
import styles from './Directory.css'

import Cookies from 'js-cookie';
import {useEffect, useState } from 'react';
import { useParams } from "react-router";


const Directory = ({  }) => {


  // function linkClick(event) {
  //   const link = event.target.closest("a").dataset.link;

  //   fetch(process.env.REACT_APP_API_URL + '/events', {
  //     method: 'POST',
  //     headers: {
  //       'membership': Cookies.get('membership'),
  //       'Authorization': Cookies.get('Authorization'),
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ 'link_id':link, 'member_id':Cookies.get('membership'), 'profile_id':profile['id'], 'variety':'outbound', 'campaign':campaign, 'referrer_url':referrer, 'device':deviceType })
  //   })
  // }
  const [links, setLinks] = useState([]);



  const fetchDataRails = () => {

    fetch(process.env.REACT_APP_API_URL + '/show_promos/' + Cookies.get('membership'), {
      headers: {
        'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setLinks(data);
      });
  }

  useEffect(() => {
    fetchDataRails();
  },[]);

  let profile_groups = {}
  links.forEach((item, i) => {
    if(!(item.display_name in profile_groups)){
      profile_groups[item.display_name] = [];
    }
    profile_groups[item.display_name].push(item)
  });



  return (
    <div className="Directory">
      <h1>Welcome to <span style={{color:'var(--quarnary)',fontWeight:'bold'}}>Affiliate Directory</span></h1>
      <p>Get discounts on websites you visit every day all while supporting your favorite creators.</p>
      <p>If you "Subscribe" to a creator's Epicenters page, their promotional links will show up here next time you visit this page.</p>
      {links.length == 0 ? <p>No discounts yet? Check out some creators: <a href='/justin'>Justin</a> </p> : ''}

      {Object.keys(profile_groups).map((key, index)=>{
        return (
          [
            <h1>{key}'s Discounts</h1>,
            <div style={{display:'grid', gridGap:'5px', gridTemplateColumns:'repeat(auto-fill, max(20em, 30%))', gridTemplateColumns:'auto'}}>
            {profile_groups[key].map((link, i)=>{
              return <a className='AffiliateLink' href={link['url']} target='_blank'> Code: <span style={{color:'var(--quarnary)'}}>{link['promo']}</span> at <span style={{fontWeight:'bold'}}>{link['title']} ↗</span></a>;
            })}
            </div>
          ]
        )
      })}
    </div>
  );
};

export default Directory;
