import React from 'react';
import './FrankenSocial.css'
import {useState, useEffect} from 'react';

import SocialButton from '../components/SocialButton'

const FrankenSocial = ({  }) => {
  let card_type = `default`;

  let card_img = ''

  let theme = {}

  let socialTypes = ['minimal','circle','inverted','frame','outline','border','glass','holo','twisted_metal','bevel']

  let [platform, setPlatform] = useState('discord');
  let [color, setColor] = useState('#259ef9');
  let platforms = ['generic_social','facebook','instagram','youtube','tiktok','linkedin','twitter','pinterest','spotify','twitch','reddit','etsy','threads','snapchat','whatsapp','paypal','venmo','discord','slack','mail']
  let colors = ['#259ef9','#cf25f9','#f92525','#f97d25','#f9ee25','#41f925']

  let i = 0;
  let j = 0;
  useEffect(()=>{
    // setInterval(()=>{
    //   setPlatform(platforms[i]);
    //   i = (i+1)%platforms.length;
    //   console.log(i);
    // },1000)

    // setInterval(()=>{
    //   setColor(colors[j]);
    //   j = (j+1)%colors.length;
    // },1000)
  },[])

  return (
    <div className='FrankenSocial'>
        {socialTypes.map((item, i) => {
          return <div class="icon-holder">
                    {item}
                    <SocialButton link_id='' platform={platform} href='' type={item} fill={color}/>
                  </div>
        })}
    </div>
  );
};

export default FrankenSocial;
