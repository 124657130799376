import './Form.css';
import styles from './Toggle.css';
import React, {useEffect, useState } from 'react';


const Toggle = ({name, label, value, onChange = (val)=>{}}) => {

  const [checked, setChecked] = useState(value ? 'checked' : '');
  // let checked = value ? 'checked' : '';

  useEffect(() => {
    setChecked(value ? 'checked' : '');
  }, [value]);

  const checkHandler = (event) =>{
    setChecked(!checked);
    // checked = value!='checked' ? 'checked' : '';
    event.target.value = event.target.checked;
    onChange(event);
  }

  return (
    <div className="Toggle">
      <div class="toggleInput">
        <input name={name} id="" class="" checked={checked} type="checkbox" onChange={checkHandler}/>
        <div class="toggleButton"></div>
      </div>
      <label>{label}</label>
    </div>
  );
};

export default Toggle;
