import styles from './Hero2.css';

import Input from '../forms/Input';
import Button from '../forms/Button';
import Popup from '../components/Popup';

import PhoneBorder from '../components/PhoneBorder';
import React, {useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';


const Hero2 = ({}) => {

  document.title = "Epicenter | Software for Growing Your Creator Business"
  document.querySelector('meta[name="description"]').setAttribute("content", "Personal Websites, Smart Links, Advanced Analytics, and Marketing Tools: all built to increase your revenue as a creator");

  const [waitlistVisible, setWaitlistVisible] = useState(false);
  const addToastMessage = useOutletContext();

  const [mockIdx, setMockIdx] = useState(0);
  let mocks = ['mock4.png','mock5.png']

  useEffect(() => {

    const script = document.createElement('script');

    script.src = "https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }


  }, []);
  // addToastMessage({type:'Success', message:'Submitted', detail:'You should hear back within a few days'})


  const a = ()=>{
    window.emailjs.init({publicKey: "GtdfAhb79CyoEPGVI"});
    setWaitlistVisible(false);

    // these IDs from the previous steps
    window.emailjs.sendForm('service_mj99f3j', 'template_xivu9wm', '#waitlistForm')
        .then(() => {
            addToastMessage({type:'Success', message:'Submitted', detail:'You should hear back within a few days'})
        }, (error) => {
            addToastMessage({type:'Fail', message:'Error', detail:'Info not submitted - email justin@epicenters.io to join waitlist'})
        });
  }

  let stars = [];
  for(let i = 0; i < 20; i++){
    let randX = Math.random();
    let randY = Math.random();
    let randDelay = Math.random();
    stars.push(<div class="star" style={{"--xCoor":randX,"--yCoor":randY,"--delay":randDelay}}></div>);
  }

  const [displayForm, updateForm] = useState("ghost");
  const showForm = () => { updateForm("") }
  const hideForm = () => { updateForm("ghost") }

  // <h1>Simple <span style={{color:'var(--quarnary)'}}>Software</span> To Increase<br/>Your Revenue as a <span style={{color:'var(--quarnary)'}}>Creator</span></h1>
  // <p>Ensure the best result by turning your online traffic into subscribers/customers/partners</p>
  // <h1>Become a <span style={{color:'var(--quarnary)'}}>Boss</span> Through Your <span style={{color:'var(--quarnary)'}}>Passion Project</span></h1>


  // <h1>Be The <span style={{color:'var(--quarnary)'}}>Boss</span> of Your <span style={{color:'var(--quarnary)'}}>Creator Business</span></h1>
  // <p>We build software to generate <span style={{fontWeight:'bold',color:'var(--quarnary)'}}>revenue</span> for your business and empower you to pursue your <span style={{fontWeight:'bold',color:'var(--quarnary)'}}>passion</span></p>



  return (
    <div className="Hero2">

      {stars}

      <div  class="main">
        <div class="img">
          <img src="graph.png" style={{width:"90%"}}></img>
        </div>

        <div class="content">
          <h1 style={{textTransform:'capitalize'}}>Unsure what is next in <span style={{color:'var(--quarnary)'}}>growing</span> your business as a <span style={{color:'var(--quarnary)'}}>creator</span>?</h1>
          <p >Run, Grow, and Optimize your solo business with purpose built software and a tailor made plan of action</p>

          <div style={{marginLeft:'0'}} class='callToAction' onClick={()=>setWaitlistVisible(true)} >Register</div>

        </div>

        <div class="info">
          <div>
            <img src="https://epicenter-main.s3.us-west-1.amazonaws.com/website.png"/>
            Personal website that actually conveys your brand to followers - built to get results
          </div>
          <div>
            <img src="https://epicenter-main.s3.us-west-1.amazonaws.com/tools.png"/>
            Unique tools make it easier for your followers to support you that you won't find anywhere else
          </div>
          <div>
            <img src="https://epicenter-main.s3.us-west-1.amazonaws.com/email.png"/>
            Lightweight, but efficient emailing solutions to stay in contact with followers, no matter what the algorithm decides
          </div>

        </div>

        <div class="mocks">
          <div>
            <div onClick={()=>setMockIdx(prevState => (mocks.length + prevState-1) % mocks.length)}>{'<'}</div>
            <img src={mocks[mockIdx]} />
            <div onClick={()=>setMockIdx(prevState => (mocks.length + prevState+1) % mocks.length)}>{'>'}</div>
          </div>
        </div>
      </div>

      <Popup  visible={waitlistVisible} setVisibility={setWaitlistVisible}>
        <form id="waitlistForm" style={{background:"#141417ff", padding:'1em'}}>
          <Input name='fullName' label='Full Name*' />
          <Input name='email' label='Email*' />
          <Input name='socialMediaHandle' label='Social Media Handle' />
          <Input name='personalWebsite' label='What main goal would you like your website to achieve?' />
          <Button label='Submit' onClick={(event)=>{event.preventDefault();a();}} />
        </form>
      </Popup>

    </div>
  )
};

export default Hero2;
