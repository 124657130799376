import './Form.css';
import styles from './Button.css';

import React, {useEffect, useState } from 'react';

// TODO: fix color and other custom styling
const Button = ({name, label, value, type="button", onClick= (val)=>{}, isPassword=false, color="white", noPadding=false, disabled=false }) => {

  let password = isPassword ? 'password' : '';

  return (
    <div className="Button" style={noPadding ? {fontSize:'1em',margin:'.2em'}:{}}>
      <button className={password} name={name} value={value} type={type} disabled={disabled} style={noPadding ? {'--color':color, marginTop:'.2em'}:{'--color':color}} onClick={onClick}>{label}</button>
    </div>
  );
};

export default Button;
