import React from 'react';
import styles from './layout5.css'
import Card from '../cards/Card';
import ContactIcon from '../components/ContactIcon';
import SocialButton from '../components/SocialButton';
import SingleLineText from '../components/SingleLineText';

import {useEffect, useState } from 'react';
import { useParams } from "react-router";

import { downloadContactCard } from '../utils/fileProcessing'


const Layout5 = ({ profile, links, theme, linkClick, subscribe }) => {

  const socialButtons = links.map((card, index) => {
      if(links[index]['section'] == 1){
        return <SocialButton href={card['url']} link_id={card['id']} type={theme['socials_style']} fill={theme['quarnary']} onClick={linkClick}/>
      }
      return null;
    })

  let primary_set = false;
  const cards = links.map((card, index) => {

      if(card['section'] == 2){
        let first = !primary_set;
        primary_set = true;
        return <Card key={index} className={theme['card_type']} link_id={card['id']} theme={theme} img={card.image?.url} type={first && theme['cta']=='default_cta' ? 'Primary' : ''} onClick={linkClick} {...card} />;
      }
      return null;
  })
  if(theme['cta'] == 'subscribe_cta'){
    cards.unshift(<Card key='-1' type='Primary' onClick={subscribe} title="Subscribe" text={profile['subscribe_copy']}/>)
  }

  let profileImgSrc = 'https://via.placeholder.com/150';
  if('image' in profile){
    profileImgSrc = profile['image']['url'];
  }


  return (
    <div className="Layout5" style={{'--primary':theme['primary'],'--secondary':theme['secondary'],'--tertiary':theme['tertiary'],'--quarnary':theme['quarnary']}}>

      <div className="profile">
        <div class="image" style={{backgroundImage:`url(${profileImgSrc})`}} />
        <div class="actions">
          { theme['collect_email'] && <div onClick={subscribe}>Subscribe</div> }
          { theme['download_contact'] && <div onClick={()=>downloadContactCard(profile.id, profile.display_name)}>Save Contact +</div> }
        </div>
      </div>

      <hr/>

      <div className="social-section">
        {socialButtons}
      </div>

      <div className="links">
        <div class='info'>
          <div class="name"><SingleLineText text={profile['display_name']}/></div>
          <div class="bio">{profile['bio']}</div>
          <div class="chev" />
        </div>

        <div className="VerticalList Scroll link-section">
          {cards}
        </div>
      </div>


    </div>
  );
};

export default Layout5;
