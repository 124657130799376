import styles from './Parallax.css';
import React, {useEffect, useState } from 'react';


const Parallax = () => {

  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  let layers = ['paral_1.svg','paral_2.svg','paral_3.svg'];

  let layer_jtml = [];

  for(let i = 0; i < layers.length; i++){
    layer_jtml.push(<div class="movable" style={{
                                            left: 'calc(var(--x) / '+(i+2)*5+' * 1px)',
                                            top: 'calc(var(--y) / '+(i+2)*5+' * 1px)',
                                            width:'110%',
                                            height:'110%',
                                          }}>
                      <img src={layers[i]} style={{
                        width:'100%',
                        height: '100%',
                      }}></img>
                    </div>);
  }

  return (
    <div className="Parallax" style={{"--x":mousePos.x,"--y":mousePos.y}}>
      {layer_jtml}
    </div>
  );
};

export default Parallax;
