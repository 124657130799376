// import styles from './Pricing.css';

import React, {useEffect, useState } from 'react';


const Popup = ({visible, setVisibility, children}) => {

  return (
    <div className="PopupBG" style={{display:visible?"flex":"none",zIndex:"10",position:"absolute",inset:"0",background:"#000a"}} onClick={()=>setVisibility(false)}>
      <div onClick={(e) => e.stopPropagation()} style={{margin:'auto'}}>
        {children}
      </div>
    </div>
  )
};

export default Popup;
