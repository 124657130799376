import React, {useEffect, useState } from 'react';
import styles from './QRGenerator.css';

import QRCode from './QRCode'
import Input from '../forms/Input'
import Button from '../forms/Button'
import Select from '../forms/Select'


const QRGenerator = ({  }) => {

  if(true){
    document.title = "QR Code Generator by Epicenter"
    document.querySelector('meta[name="description"]').setAttribute("content", "The easiest to use QR code maker. Type in your data and get your QR code in  milliseconds. Forever free to use.");
  }
  

  function asdf(event, update){
    let { name, value } = event.target;
    update(value);
  }

  const [data, setData] = useState("");
  const [color, setColor] = useState("#ffff");
  const [background, setBackground] = useState("transparent");
  const [efficiency, setEfficiency] = useState("H");
  const [marker, setMarker] = useState("circle");
  const [point, setPoint] = useState("circle");


  let qr = <QRCode data={data} color={color} background={background} pointStyle={point} markerStyle={marker} redundancy={efficiency}/>;

  function saveQR(){
    const blob =  new Blob([document.querySelector('#QRSVG').outerHTML], { type: 'text/plain' });

    //create a url to reference this downloaded file
    const url = window.URL.createObjectURL(blob);

    //create an anchor tag with this url
    const link = document.createElement('a');
    link.href = url;

    //set name of file (download attribute)
    link.setAttribute('download', 'qrCode.svg');
    document.body.appendChild(link);

    //click link to trigger the file download
    link.click();
  }

  return (
    <div className="QRGenerator" style={{"--primary":"blue"}} >

      <div class="qrContent" >
        <div class="QRInputs">
          <Input name={'data'} label={'QR Data'} value={data} onChange={(event)=>{let {name, value} = event.target;setData(value);}} />
          <Input name={'color'} label={'Color'} value={color} onChange={(event)=>{let {name, value} = event.target;setColor(value);}} />
          <Input name={'background'} label={'Background'} value={background} onChange={(event)=>{let {name, value} = event.target;setBackground(value);}} />
          <Select name={'marker'} label={'Marker Style'} value={marker} options={[{id:'square',name:'Square'},{id:'circle',name:'Circle'},{id:'round',name:'Round'}]} onChange={(event)=>{let {name, value} = event.target;setMarker(value);}} />
          <Select name={'point'} label={'Point Style'} value={point} options={[{id:'square',name:'Square'},{id:'circle',name:'Circle'},{id:'round',name:'Round'}]} onChange={(event)=>{let {name, value} = event.target;setPoint(value);}} />
          <Select name={'efficiency'} label={'Efficiency'} value={efficiency} options={[{id:'H',name:'Low'},{id:'M',name:'Medium'},{id:'L',name:'High'}]} onChange={(event)=>{let {name, value} = event.target;setEfficiency(value);}} />
          <Button name={'Download'} label={'Download'} onClick={()=>{saveQR()}}/>
        </div>
        <div class="QRContainer">
          {qr}
        </div>
      </div>

    </div>
  );
};

export default QRGenerator;
