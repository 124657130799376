import styles from './Template.css';

import Header from './Header';
import Footer from './Footer';

import React, {useEffect, useState } from 'react';
import {Outlet} from 'react-router-dom';
import { useOutletContext } from "react-router";


const Template = ({showFooter=true, children}) => {

  const addToastMessage = useOutletContext();

  return (
    <div className="Template">

      <Header />

      {children}
      <Outlet context={addToastMessage}/>

      {showFooter && <Footer />}
      <div style={{position:'absolute',opacity:'0', pointerEvents:'none'}}><Footer /></div>

    </div>
  )
};

export default Template;
