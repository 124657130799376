import styles from './ListView.css';
import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link  } from 'react-router-dom';
import { useParams } from "react-router";


const ListView = ({headers, data, showTable, alternateRedirect}) => {
   const route = window.location.pathname;
   let { table } = useParams();
   table = showTable ? showTable : table; //if we've used a different route to get to this table, we need to show the right table
   table = table.slice(0,-1);

   let homeDir = '';


   const [tableDefinition, setTableDefinition] = useState({});
   const [records, setRecords] = useState({});

   const fetchDefinition = () => {
     // fetch table definition this is used to load form (we also use "value" of each key to submit data for both create and update form submission)
     fetch(process.env.REACT_APP_API_URL + '/' + table + '/define', {
       headers: {
         'Authorization': Cookies.get('Authorization')
       }
     })
       .then((response) => response.json())
       .then((data) => {
         setTableDefinition(data);
       });

   }

   const fetchRecords = () =>{
     // if we do not create a new record, then we need to render inital values of each input
     fetch(process.env.REACT_APP_API_URL + '/' + table + 's', {
       headers: {
         'Authorization': Cookies.get('Authorization')
       }
     })
       .then((response) => response.json())
       .then((data) => {
         setRecords(data);
       });
   }

   useEffect(() => {
     fetchDefinition();
     fetchRecords();
   },[table]);


   const tableFeatures = Object.keys(tableDefinition).map((key, index) => {
     return <div class='cell'>{key}</div>;
     return null;
   })

   const tableRecords = Object.keys(records).map((key, index) => {
     let row = [];

     for(let i = 0; i < Object.keys(tableDefinition).length; i ++ ){
       let tableKey = Object.keys(tableDefinition)[i];
       let linkUrl = homeDir + '/admin/'+table+'s/'+records[key]['id'];
       if(alternateRedirect){
        linkUrl = homeDir + alternateRedirect + records[key]['id']
       }

       row.push(
         <div class='cell'>
          <a href={linkUrl} >
            {records[key][tableKey]}
          </a>
        </div>
        );
     }

     return <div class='record'>{row}</div>;
   })


    return (
      <div className="ListView">

        <a href={homeDir + route+'/add'} >
          Create
        </a>

        <div class='scroll-wrapper'>
          <div class='table'>
            <div class='tableFeatures'>
              {tableFeatures}
            </div>
            {tableRecords}
          </div>
        </div>

      </div>
    );
};

export default ListView;
