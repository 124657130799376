import React, { useState, useEffect, useRef } from 'react';

const SingleLineText = ({text}) => {

  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState({});

  useEffect(() => {
    const handleResize = () =>{
      const textElement = textRef.current;
      const containerWidth = textRef.current.parentNode.getBoundingClientRect().width;

      let currFontSize = parseInt(window.getComputedStyle(textElement, null).getPropertyValue('font-size'));
      let targetSize = parseInt(window.getComputedStyle(textRef.current.parentNode, null).getPropertyValue('font-size'));
      let newFontSize = parseInt(currFontSize * (containerWidth/textElement.offsetWidth));

      // if the container size can't fit the full text (which newFontSize is the font size that will fit the container's width perfectly) then we set the text to fill continer width
      if(newFontSize < targetSize){
        setFontSize({fontSize:`${newFontSize}px`});
      // else we unset the explicit font size so that the text can inherit the parent's style
      }else if(fontSize != {}){
        setFontSize({});
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [text]);



  return (
    <div className="SingleLineText" ref={textRef} style={{display:"inline", ...fontSize}}>
      {text}
    </div>
  )
};

export default SingleLineText;
