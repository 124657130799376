import React from 'react';
import styles from './Footer.css'

const Footer = ({ footerText, link='' }) => {
  return (
    <div className="Footer">
      <a href={link}>

        <svg id="a" class="" viewBox="0 0 175 116" preserveAspectRatio="xMidYMid meet">
          <use href="/logo_nofill.svg#layer1"></use>
        </svg>

        <span class="simple-footer-text">{footerText}</span>
      </a>
    </div>
  );
};

export default Footer;
