import './Form.css';
import styles from './Number.css';
import React, {useEffect, useState } from 'react';


const Number = ({name, label, value, onChange = (val)=>{}}) => {


  return (
    <div className="Number">
      <input name={name} value={value} onChange={onChange}  placeholder={label} type="number"/>
      <label>{label}</label>
    </div>
  );
};

export default Number;
