import './Form.css';
import styles from './Input.css';

import React, {useEffect, useState } from 'react';


const File = ({name, label, value, onChange= (val)=>{}, isPassword=false}) => {

  return (
    <div className="File">
      <input type='file' name={name} value={value} onChange={onChange} placeholder={label}/>
      <label>{label}</label>
    </div>
  );
};

export default File;
