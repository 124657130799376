import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams } from "react-router";

function LinkRedirect({  }) {
  let { link_id } = useParams();

  const [redirectUrl, setRedirectUrl] = useState(null);

  let tst;
  useEffect(() => {
    async function fetchRedirectUrl() {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/link/${link_id}/go`, {
        headers: {
          'membership': Cookies.get('membership')
        }
      });
      const data = await response.json();
      if(data.url && data.url != null){
        setRedirectUrl(data.url);
      }else{
        setRedirectUrl('https://epicenters.io');
      }

      //set the catalogID
      if(data.membership && data.membership != null){
        Cookies.set('membership', data.membership)
      }
    }
    fetchRedirectUrl();
  }, [link_id]);

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  return (
    <div style={{height:"100%", minHeight:"90vh", display:"flex", flexDirection:"col"}}>
      <div style={{margin:"auto"}}>
        <span style={{fontSize:"3em", fontWeight:"bolder"}}>Redirecting...</span>
        <br/>
        <br/>
        <span style={{fontSize:"2em"}}>want your own dynamic qr codes or link in bio page?</span>
        <br/>
        <br/>
        <span style={{fontSize:"2em"}}> Sign up with <a href="https://epicenters.io" style={{textDecoration:"none", fontWeight:"bolder", color:"var(--quarnary)"}}>Epicenters</a> </span>
      </div>
    </div>
  );
}

export default LinkRedirect;
