import React from 'react';
import styles from './Loader.css'

const Loader = ({ display=true, style={} }) => {
  if(!display){
    style.display = 'none';
  }
  return (
    <div className="Loader" style={style}>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  );
};

export default Loader;
