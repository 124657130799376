import styles from './CRUD.css';
import MultiSelect from '../forms/MultiSelect';
import Select from '../forms/Select';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Toggle from '../forms/Toggle';
import Number from '../forms/Number';
import ColorPicker from '../forms/ColorPicker';
import File from '../forms/File';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams, useOutletContext } from "react-router";


const CRUD = () => {
  let { table, id } = useParams();
  table = table.slice(0,-1);
  const addToastMessage = useOutletContext();


  let homeDir = '';

  const [tableDefinition, setTableDefinition] = useState({});
  const [tableOptions, setTableOptions] = useState({});

  const fetchDefinition = () => {
    // fetch table definition this is used to load form (we also use "value" of each key to submit data for both create and update form submission)
    fetch(process.env.REACT_APP_API_URL + '/' + table + '/define', {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        for(const [key, definition] of Object.entries(data)){
          setTableDefinition(prevState => ({...prevState, [key]:{...prevState[key], ...data[key]}}));
          //if the key is a reference, then we need to get all reference options
          if(definition['type'] == 'reference' && !(key.slice(0,-3) + 's' in tableOptions) ){
            let refTable = definition.table || key.slice(0,-3) + 's';
            fetchOptions(refTable);
          }

        }
        // setTableDefinition(prevState => ({...prevState, ...data}));
      });

  }

  const fetchCurrentValues = () =>{
    // if we do not create a new record, then we need to render inital values of each input
    fetch(process.env.REACT_APP_API_URL + '/' + table + 's/' + id, {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        let tempData = {};
        for(const [key, value] of Object.entries(data)){
          tempData[key] = {'value': value};
          setTableDefinition(prevState => ({ ...prevState, [key]: {...prevState[key], value:value}}));
        }
        // setTableDefinition(prevState => ({ ...prevState, ...tempData}))
      });
  }

  const fetchOptions = (tableName) =>{
    // if we do not create a new record, then we need to render inital values of each input
    fetch(process.env.REACT_APP_API_URL + '/' + tableName, {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if(tableName == 'links'){
        data.forEach((item, i) => {
          item['name'] = item['title'];
        });

      }
      setTableOptions(prevState => ({ ...prevState, [tableName]: data}));
    });
  }

  useEffect(() => {
    fetchDefinition();
    if(id != 'add'){
      fetchCurrentValues();
    }
  },[table, id]);

  const handleInputChange = (event) => {
    let { name, value, files } = event.target;
    if(tableDefinition[name]['type'] == 'file'){
      setTableDefinition(prevState => ({ ...prevState, [name]: {...prevState[name], value:value, file:files[0]}}));
    }else{
      setTableDefinition(prevState => ({ ...prevState, [name]: {...prevState[name], value:value}}));
    }
    // console.log(tableDefinition);
  }

  const handleSubmit = (event) => {
    // console.log(tableDefinition);
    // let params = {};
    var data = new FormData()
    let tmp = Object.keys(tableDefinition).map(key => {
      if(tableDefinition[key]['editable']){
        if(tableDefinition[key]['type'] == 'file' && tableDefinition[key]['file']){
          data.append(key, tableDefinition[key]['file'])
        }else if(tableDefinition[key]['value']){
          // params[key]= tableDefinition[key]['value'];
          data.append(key, tableDefinition[key]['value'])
        }
      }
      return {[key]: tableDefinition[key]['value']};
    });

    let url, method;
    if(id == 'add'){
      url = '/' + table + 's/';
      method = 'POST';
    }else{
      url = '/' + table + 's/' + id;
      method = 'PUT';
    }

    fetch(process.env.REACT_APP_API_URL + url, {
      method: method,
      // body: JSON.stringify(params),
      body: data,
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': Cookies.get('Authorization')
      }
    })
      .then(response => {
        console.log(response);
        addToastMessage({type:'Success', message:'Success', detail:'Data Saved'});
      })
      .catch(error => {
        console.error(error);
        addToastMessage({type:'Error', message:'Error', detail:'Data Could Not Be Submitted'});
      });
  }

  const deleteRecord = () => {
    fetch(process.env.REACT_APP_API_URL + '/' + table + 's/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Cookies.get('Authorization')
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
    })
  }
  // default values to use for testing
  // tableDefinition['testInput'] = {"editable":true,"type":"string"};
  // tableDefinition['testToggle'] = {"editable":true,"type":"bool"};
  // tableDefinition['testMultiSelect'] = {"editable":true,"type":"selection"};
  // tableDefinition['testColorPicker'] = {"editable":true,"type":"color"};

  // for each field in our table, we need to check if it is editable and which type of input we will use
  const inputs = Object.keys(tableDefinition).map((key, index) => {
    let editable = tableDefinition[key]['editable'];
    let type = tableDefinition[key]['type'];

    if(editable){
      // console.log(tableDefinition[key]['value']);
      if(type == 'bool'){
        return <Toggle name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange}/>;
      }else if (type == 'string') {
        return <Input name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange}/>;
      }else if (type == 'reference') {
        let refTable = tableDefinition[key].table || key.slice(0,-3) + 's';
        return <Select name={key} label={key} value={tableDefinition[key]['value']} options={tableOptions[refTable]} onChange={handleInputChange}/>;
      }else if (type == 'enum') {
        let theseOptions = Object.entries(tableDefinition[key]['options']).map((key, value) => { return {"id":key[0], "value":key[0], "name":key[0]} })
        return <Select name={key} label={key} value={tableDefinition[key]['value']} options={theseOptions} onChange={handleInputChange}/>;
      }else if (type == 'selection') {
        return <MultiSelect label={'color'} options={['blue','red']}/>;
      }else if (type == 'int') {
        return <Number name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange}/>;
      }else if (type == 'color') {
        return <ColorPicker name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange}/>;
      }else if (type == 'file') {
        return <File name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange}/>;
      }
    }
    return null;
  })



  return (
    <div className="CRUD">

        <Button onClick={deleteRecord} label="Delete"/>

        {inputs}

        <Button onClick={handleSubmit} label="Submit"/>
    </div>
  );
};

export default CRUD;
