import styles from './Header.css';

import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const Header = ({sticky=false}) => {

  let home = <Link to="/"><img src="/logo_full.png"/></Link>;

  let links = [{href:"/about", text:"About"},{href:"/pricing", text:"Pricing"},{href:"/qr-maker", text:"QR Codes"}]

  let cta = <a class="CTA" href="/login">Login</a>;

  const [mobileDrawer, openMobileDrawer] = useState(false);


  return (
    <div className="Header">

      <div class="HeaderStart">
        {home}
      </div>

      <div class="DesktopMenu">
        {links.map((link, idx) =>{
          return(<div class="Link">
            <Link to={link.href}>{link.text}</Link>
          </div>)
        })}
      </div>

      <div class="HeaderEnd">
        <div class="Hamburger" onClick={() => openMobileDrawer(prevDrawer => !prevDrawer)}>
          <img src={(mobileDrawer ? 'X.png' : 'hamburger.png')}/>
        </div>
        {cta}
      </div>



      <div class={"MobileMenu " + (mobileDrawer ? 'open' : '')}>

        {links.map((link, idx) =>{
          return(<div class="Link">
            <Link to={link.href}>{link.text}</Link>
          </div>)
        })}

        {cta}
      </div>


    </div>
  )
};

export default Header;
