import React from 'react';
import styles from './Footer.css'
import { Link } from 'react-router-dom';

import SocialButton from '../components/SocialButton'

const Footer = ({ footerText }) => {

  const socials = ['https://www.instagram.com/epicentersio/'];
  const footerLinks = ['Privacy Policy',  'Affiliate Directory',  'Blog']//'Terms and Conditions'


  return (
    <div className="Footer">
      <Link to='/'>
        <span class="simple-footer-text"><img src="/logo.png" style={{filter:'grayscale() brightness(2)', maxHeight:'1em'}}></img> Epicenters.io</span>
      </Link>

      <div class="FooterSocials">
        {socials.map((item, idx) =>{
          return <SocialButton href={item} link_id={'id'} type={'basic'} fill={'var(--tertiary)'}/>
        })}
      </div>

      <div class="FooterLinks">
        {footerLinks.map((item, idx) => {
          if(item === 'Blog'){
            return <a href='https://blog.epicenters.io'>{item}</a>
          }else{
            return <Link to={item.toLowerCase().replace(' ', '-')}>{item}</Link>
          }
        })}
      </div>
    </div>
  );
};

export default Footer;
