import './Form.css';
import styles from './Select.css';
import React, {useEffect, useState } from 'react';


const Select = ({name, label, value, options, onChange= (val)=>{}}) => {

  if(options === undefined){
    options = [];
  }
  if(value === undefined){
    value = '';
  }

  return (
    <div className="Select">
      <select name={name} onChange={onChange} value={value} required>
        <option value='' disabled selected>-- Select {label}--</option>
        {options.map((x, idx)=> <option value={x['id']}>{x['name']?x['name']:x['id']}</option>)}
      </select>
      <label>{label}</label>
    </div>
  );
};

export default Select;
