import './Form.css';
import styles from './Input.css';

import React, {useEffect, useState } from 'react';


const Input = ({name, label, value, onChange= (val)=>{}, isPassword=false}) => {

  let password = isPassword ? 'password' : '';

  return (
    <div className="Input">
      <input className={password} type={password} name={name} value={value} onChange={onChange} placeholder={label}/>
      <label>{label}</label>
    </div>
  );
};

export default Input;
