import styles from './ToastMessageDisplay.css'

import Toast from './Toast'

const ToastMessageDisplay = ( { messages, setMessages } ) => {

  // useEffect(()=>{
  //   setInterval(()=>{
  //     let toasts = document.querySelector('.ToastMessageDisplay').children;
  //     if(toasts.length > 0){
  //       let first_opacity = window.getComputedStyle(toasts[0]).opacity;
  //       if(first_opacity <= 0.0001){
  //         console.log(messages)
  //         console.log(messages.slice(1))
  //         setMessages(prevState => prevState.slice(1))
  //       }
  //       console.log(first_opacity)
  //     }
      
  //   },1000);
  // },[])

  // const [myMessages, updateMyMessages] = useState()

  const removeMessage = (id) => {
    setMessages(prevState => prevState.filter(message => message.id !== id))
  }
  const d = new Date();
  let time = d.getTime();

  return (
    <div className="ToastMessageDisplay" >
      {messages.map((message, id)=>{
        return <Toast id={message.id} type={message.type} message={message.message} detail={message.detail} close={removeMessage}/>
      })}
    </div>
  );
};

export default ToastMessageDisplay;
