import './Form.css';
import styles from './MultiSelect.css';
import React, {useEffect, useState } from 'react';


const MultiSelect = ({name, label, options}) => {

  const [selectedOptions, setSelectedOptions] = useState([]);

  return (
    <div className="MultiSelect">
      <select multiple name={name} >
        {options.map((x, idx)=> <option>{x}</option>)}
      </select>
      <label>{label}</label>
    </div>
  );
};

export default MultiSelect;
