import React from 'react';
import styles from './QRCode.css';

// import QRCode from 'qrcode-generator';
import {encodeQR} from '../utils/fileProcessing'

const QRCode = ({ data='', pointStyle='square', markerStyle='square', color="#000000ff", background="#ffffffff", redundancy='H' }) => {
  
  //how big 1 pixel of the qr code should be
  let scale = 10; //I'm using this in a lot of places, so making it easier on myself to find when I do end up changing...

  let qr = encodeQR(data, redundancy);

  
  // return (
  //   <div className="QRCode" style={{display:'grid', padding:'5px', width:'fit-content', height:'fit-content', background:background}}>
      
  //     {qr.map((value, i) => (
  //       <div style={{display:'flex', flexDirection: 'row', margin:'0'}}>
  //       {value.map((black, j) => (
  //         <div style={{width:"10px", height:"10px", background: black ? color : '#00000000'}}></div>
  //       ))}
  //       </div>
  //     ))}

  //   </div>
  // );

  //start x,y of all the markers
  let markerStarts = [ [0,0], [qr.length - 7, 0], [0, qr.length - 7] ]
  

  //Remove markers from qr data
  markerStarts.map((marker, _) =>{
    for(let x = 0; x < 7; x ++){
      for(let y = 0; y < 7; y ++){
        qr[x + marker[0] ][y + marker[1] ] = false;
      }
    }
  })

  let markers=[]
  markerStarts.map((marker, _) =>{
    if( markerStyle == 'circle'){
      let points = `M ${(marker[0] + 4.5) * scale} ${(marker[1] + 1) * scale}
                      A ${3.5 * scale} ${3.5 * scale} 0 1 1 ${(marker[0] + 4.5 -.0001) * scale} ${(marker[1] + 1) * scale}
                    M ${(marker[0] + 4.5) * scale} ${(marker[1] + 2) * scale}
                      A ${2.5 * scale} ${2.5 * scale} 0 1 0 ${(marker[0] + 4.5 + .0001) * scale} ${(marker[1] + 2) * scale}
                    M ${(marker[0] + 4.5) * scale} ${(marker[1] + 3) * scale}
                      A ${1.5 * scale} ${1.5 * scale} 0 1 1 ${(marker[0] + 4.5 -.0001) * scale} ${(marker[1] + 3) * scale}`
      markers.push( <path stroke="none" fill={color} d={points} /> );
    }
    else if( markerStyle == 'round'){
      let points = `M ${(marker[0]+2) * scale} ${(marker[1]+1) * scale} 
                      h ${5 * scale} c ${scale/2} 0 ${scale} ${scale/2} ${scale} ${scale}
                      v ${5 * scale} c 0 ${scale/2} -${scale/2} ${scale} -${scale} ${scale}
                      h -${5 * scale} c -${scale/2} 0 -${scale} -${scale/2} -${scale} -${scale}
                      v -${5 * scale} c 0 -${scale/2} ${scale/2} -${scale} ${scale} -${scale}
                    M ${(marker[0]+2) * scale} ${(marker[1]+2.5) * scale} 
                      v ${4 * scale} c 0 ${scale/4} ${scale/4} ${scale/2} ${scale/2} ${scale/2}
                      h ${4 * scale} c ${scale/4} 0 ${scale/2} -${scale/4} ${scale/2} -${scale/2}
                      v -${4 * scale} c 0 -${scale/4} -${scale/4} -${scale/2} -${scale/2} -${scale/2}
                      h -${4 * scale} c -${scale/4} 0 -${scale/2} ${scale/4} -${scale/2} ${scale/2}
                    M ${(marker[0]+3.5) * scale} ${(marker[1]+3) * scale} 
                      h ${2 * scale} c ${scale/4} 0 ${scale/2} ${scale/4} ${scale/2} ${scale/2}
                      v ${2 * scale} c 0 ${scale/4} -${scale/4} ${scale/2} -${scale/2} ${scale/2}
                      h -${2 * scale} c -${scale/4} 0 -${scale/2} -${scale/4} -${scale/2} -${scale/2}
                      v -${2 * scale} c 0 -${scale/4} ${scale/4} -${scale/2} ${scale/2} -${scale/2}`
          markers.push( <path stroke="none" fill={color} d={points} /> );      
    }
    else{
      let points = `M ${(marker[0] + 1) * scale} ${(marker[1] + 1) * scale} h ${7*scale} v ${7*scale} h -${7*scale} v -${7*scale} 
                    M ${(marker[0] + 2) * scale} ${(marker[1] + 2) * scale} v ${5*scale} h ${5*scale} v -${5*scale} h -${5*scale} 
                    M ${(marker[0] + 3) * scale} ${(marker[1] + 3) * scale} h ${3*scale} v ${3*scale} h -${3*scale} v -${3*scale}`;
      markers.push( <path stroke="none" fill={color} d={points} /> );
    }
  })
  

  

  let paths = [];
  qr.map((value, i) => {

    value.map((isBlack, j) => {

      if(isBlack){
        if( pointStyle == 'circle'){
          paths.push( <circle cx={`${(i + 1.5) * scale}`} cy={`${(j + 1.5) * scale}`} r={scale/2} stroke="none" fill={color} /> )
        }
        else if(pointStyle == 'round'){
          //TODO: make relative paths
          let r1 = (i==qr.length-1 || !qr[i+1][j]) && (j == 0 || !qr[i][j-1]);
          let r2 = (i==qr.length-1 || !qr[i+1][j]) && (j == qr.length - 1 || !qr[i][j+1]);
          let r3 = (i==0 || !qr[i-1][j]) && (j == qr.length - 1 || !qr[i][j+1]);
          let r4 = (i==0 || !qr[i-1][j]) && (j == 0 || !qr[i][j-1]);
          //h ${scale/2} v ${scale/2} 
          let points = `M ${(i+1.5) * scale} ${(j+1) * scale} `+
                          (r1 ? `C ${(i+1.75)*scale} ${(j+1)*scale} ${(i+2)*scale} ${(j+1.25)*scale} ${(i+2) * scale} ${(j+1.5) * scale} ` : `h ${scale/2} v ${scale/2} `) +
                          (r2 ? `C ${(i+2)*scale} ${(j+1.75)*scale} ${(i+1.75)*scale} ${(j+2)*scale} ${(i+1.5) * scale} ${(j+2) * scale} ` : `v ${scale/2} h -${scale/2} `) +
                          (r3 ? `C ${(i+1.25)*scale} ${(j+2)*scale} ${(i+1)*scale} ${(j+1.75)*scale} ${(i+1) * scale} ${(j+1.5) * scale} ` : `h -${scale/2} v -${scale/2} `) +
                          (r4 ? `C ${(i+1)*scale} ${(j+1.25)*scale} ${(i+1.25)*scale} ${(j+1)*scale} ${(i+1.5) * scale} ${(j+1) * scale} ` : `v -${scale/2} h ${scale/2} `);
          paths.push( <path stroke={color} stroke-width='.5' fill={color} d={points} /> )
        }
        else{
          let points = `M ${i*scale + scale} ${j*scale + scale} h ${scale} v ${scale} h -${scale} v -${scale} `;
          paths.push( <path stroke={color} stroke-width='.3' fill={color} d={points} /> )
        }
      }

    })

  })

  return (

    <svg id="QRSVG" class="QRCode" viewBox={`0 0 ${(qr.length + 2) * scale} ${(qr.length + 2) * scale}`} width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{backgroundColor:background}}>

      <defs>
        
      </defs>

      {markers}
      {paths}

    </svg>
    
  );
};

export default QRCode;
