import styles from './PasswordReset.css';
import MultiSelect from '../forms/MultiSelect';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Toggle from '../forms/Toggle';
import ToastContext from '../App';
import React, {useEffect, useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { useParams, useOutletContext } from "react-router";


const PasswordReset = () => {
  let { table, id } = useParams();
  const addToastMessage = useOutletContext();

  let reset_token = new URLSearchParams(window.location.search).get("id");

  let homeDir = '';

  const [tableDefinition, setTableDefinition] = useState({"email":{"editable":true,"type":"string"}});
  

  useEffect(()=>{
    if(reset_token){
      setTableDefinition({"password":{"editable":true,"type":"string"},"confirm password":{"editable":true,"type":"string"}})
    }
  }, reset_token)

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setTableDefinition(prevState => ({ ...prevState, [name]: {...prevState[name], 'value':value}}));
  }

  // for each field in our table, we need to check if it is editable and which type of input we will use
  // tableDefinition['email'] = {"editable":true,"type":"string"};
  // tableDefinition['password'] = {"editable":true,"type":"string"};

  const inputs = Object.keys(tableDefinition).map((key, index) => {
    let editable = tableDefinition[key]['editable'];
    let type = tableDefinition[key]['type'];

    if(editable){
      if(type == 'bool'){
        return <Toggle name={key} label={key}/>;
      }else if (type == 'string') {
        return <Input name={key} label={key} value={tableDefinition[key]['value']} onChange={handleInputChange} isPassword={ ['password','confirm password'].includes(key) ? true:false}/>;
      }else if (type == 'selection') {
        return <MultiSelect label={'color'} options={['blue','red']}/>;
      }
    }
    return null;
  })
  // onChange={(e) => setPassword(e.target.value)}

  const handleSubmit = (event) => {
    event.preventDefault();

    if(reset_token){
      fetch(process.env.REACT_APP_API_URL + '/password_reset', {
        headers: {
          'Content-Type': 'application/json'
        },
        method:'POST',
        body: JSON.stringify({ token:reset_token, password: tableDefinition['password']['value'] })
        // body: new URLSearchParams({ email: email, password: password }).toString()
      })
        .then((response) => response.json())
        .then((data) => {
          // if('auth_token' in data){
          //   addToastMessage({type:'Success', message:'Success', detail:'Logged In: Redirecting'})
          //   Cookies.set('Authorization', data['auth_token']);
            var link = document.createElement('a');
            link.href = `${homeDir}/login`;
            document.body.appendChild(link);
            link.click();
          //   // <a href={`${homeDir}/admin/profiles/${profile['id']}`}  >Profile</a>
          // }else{
          //   addToastMessage({type:'Error', message:'Error', detail:'Login details are incorrect'})
          // }
        });
    }else{
      fetch(process.env.REACT_APP_API_URL + '/request_password_reset', {
        headers: {
          'Content-Type': 'application/json'
        },
        method:'POST',
        body: JSON.stringify({ email: tableDefinition['email']['value'] })
      })
        .then((response) => response.json())
        .then((data) => {
          addToastMessage({type:'Success', message:'Success', detail:'Password reset email has been sent.'})
        });
    }
    
  };

  return (
    <div className="PasswordReset">
      <form onSubmit={handleSubmit} style={{margin:"auto"}}>
        {inputs}
        <a href="/login" style={{color:'white',textAlign:'left'}}>Back To Login</a>

        <Button type="submit" label="Submit"/>

      </form>
    </div>
  );
};

export default PasswordReset;
