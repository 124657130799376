import './Form.css';
import styles from './ColorPicker.css';
import React, {useEffect, useState } from 'react';

import { hexToHSL, hslToHex } from '../utils/colorFunctions';


const ColorPicker = ({name, label, value, onChange= (val)=>{} }) => {

  const colorPickerOnChange = (event) => {
    onChange(event);
  }
  const updateInput = (hue, saturation, lightness) =>{
    let color = hslToHex(hue, saturation, lightness);
    let input = document.querySelector('[name='+name+']');
    input.value = color;
    let ev = new Event('change');
    input.dispatchEvent(ev);
    colorPickerOnChange(ev);
    input.focus();
  }

  const clickWheel = (event, oneClick=false) => {
    let curCol = hexToHSL(value);
    let bounds = event.target.getBoundingClientRect();
    let x = event.clientX - bounds.left - (bounds.width/2);
    let y = event.clientY - bounds.top - (bounds.height/2);
    let hue = 90 - Math.atan(x/y) * (180/Math.PI);
    if(y < 0){hue += 180}
    let saturation = Math.min(100, ((x**2 + y**2) / (bounds.width/2)**2)*100);

    if(wheelMouseDown || oneClick){
      updateInput(hue, saturation, curCol.lightness || 50);
    }
  }

  const clickShadeBar = (event, oneClick=false) => {
    let curCol = hexToHSL(value);
    let bounds = event.target.getBoundingClientRect();
    let y = event.clientY - bounds.top;
    let lightness = 100 - (100 * y / bounds.height);

    if(sliderMouseDown || oneClick){
      updateInput(curCol.hue, curCol.saturation, lightness)
    }

  }
  const setWheelPos = (event, oneClick=false) =>{
    let bounds = event.target.getBoundingClientRect();
    if(wheelMouseDown || oneClick){
      setWheelXY([`${event.clientX - bounds.left}px`, `${event.clientY - bounds.top}px`])
    }
  }

  const [selectedHue, setSelectedHue] = useState(0);
  const [selectedSaturation, setSelectedSaturation] = useState(0);
  const [selectedLightness, setSelectedLightness] = useState(0);
  const [wheelMouseDown, setWheelMouseDown] = useState(false);
  const [sliderMouseDown, setSliderMouseDown] = useState(false);

  const [wheelXY, setWheelXY] = useState(['3.5em','3.5em']);

  useEffect(() => {
    setSelectedHue(hexToHSL(value).hue);
    setSelectedSaturation(hexToHSL(value).saturation);
    setSelectedLightness(hexToHSL(value).lightness);
  }, [value]);

  return (
    <div className="ColorPicker">
      <div class="inputWrapper" style={{"--color":value,"--selectedHue":selectedHue,"--selectedSaturation":selectedSaturation,"--selectedLightness":selectedLightness}}>
        <input name={name} value={value} onChange={colorPickerOnChange} placeholder={label}/>
        <div class="ColorPreview" style={{background:value}}></div>
        <div class="ColorDrawer" onMouseUp={()=>{setSliderMouseDown(false); setWheelMouseDown(false)}} onMouseMove={(e)=>{clickShadeBar(e); clickWheel(e); setWheelPos(e);}}>
          <div class="ColorWheel" style={{"--wheelX":wheelXY[0],"--wheelY":wheelXY[1]}}onMouseDown={()=>{setWheelMouseDown(true);}} onClick={(e)=>{clickWheel(e,true);setWheelPos(e,true);}}>
            <div class="Handle"></div>
          </div>
          <div class="ColorShadeBar" onMouseDown={()=>{setSliderMouseDown(true);}} onClick={(e)=>{clickShadeBar(e,true)}} >
            <div class="Handle"></div>
          </div>
        </div>
      </div>
      <label>{label}</label>
    </div>
  );
};

export default ColorPicker;
