import styles from './Toast.css'

const Toast = ({ id, type, message, detail, close=()=>{} }) => {

  const timerId = setTimeout(()=>{
    close(id)
    }, 10000)

  let bubble_attr = [
      {"--start-x": "0%", "--start-y": "0%", "--size": "90px"},
      {"--start-x": "95%", "--start-y": "95%", "--size": "3.4em"},
      {"--start-x": "60%", "--start-y": "120%", "--size": "13px", "--duration": "7s", "--offset": "-8s"},
      {"--start-x": "89%", "--start-y": "120%", "--size": "13px", "--duration": "4s", "--offset": "-1s"},
      {"--start-x": "98%", "--start-y": "120%", "--size": "18px", "--duration": "4s", "--offset": "-2s"},
      {"--start-x": "85%", "--start-y": "120%", "--size": "15px", "--duration": "4s", "--offset": "-6s"},
      {"--start-x": "120%", "--start-y": "116%", "--size": "17px", "--duration": "4s", "--offset": "-8s"},
      {"--start-x": "120%", "--start-y": "111%", "--size": "15px", "--duration": "7s", "--offset": "-8s"},
      {"--start-x": "52%", "--start-y": "120%", "--size": "16px", "--duration":"8s", "--offset": "-6s"},
      {"--start-x": "120%", "--start-y": "46%", "--size": "18px", "--duration": "4s", "--offset": "-8s"},
      {"--start-x": "75%", "--start-y": "120%", "--size": "13px", "--duration": "4s", "--offset": "-1s"},
      {"--start-x": "120%", "--start-y": "114%", "--size": "17px", "--duration": "5s", "--offset": "-1s"}
    ];
  let bubbles = bubble_attr.map((style) => <div className="bubble" style={style}></div>);

  return (
    <div className={`Toast ${type}`}>
      
      <div className="animation-container">
        <div className="animation">
          {bubbles}
        </div>

      </div>
      <div className="icon">

      </div>

      <div className="content">
        <div className="message">
          {message}
        </div>
        <div className="detail">
          {detail}
        </div>
        <div className="close" onClick={() => close(id)}>
          X
        </div>
      </div>

    </div>
  );
};

export default Toast;
