import styles from './SocialButton.css';
import React, {useEffect, useState } from 'react';


const SocialButton = ({key, link_id, platform, href, type, fill, onClick}) => {
  // type = 'minimal'; //'block' 'minimal' 'solo'
  if(!['minimal','frame','inverted','outline','border','holo','twisted_metal','circle','glass','bevel'].includes(type)){
    type = 'minimal';
  }

  let platforms = ['generic_social','facebook','instagram','youtube','tiktok','linkedin','twitter','pinterest','spotify','twitch','reddit','etsy','threads','snapchat','whatsapp','paypal','venmo','discord','slack','mail'];

  if(platform === undefined && href){
    platform = 'generic_social';
    for(let i = 0; i < platforms.length; i++){
      if(href.toLowerCase().includes(platforms[i])){
        platform = platforms[i];
      }
    }
  }

  let src = '' + platform + '.svg#layer1';;
  // if(type == 'block' || type == 'minimal'){
  //   src = 'https://the-cutting-edges.com/static/images/' + platform + '.svg#layer1';
  // }else if(type =='solo'){
  //   src = 'https://the-cutting-edges.com/static/images/' + platform + '_solo.svg#layer1';
  // }

  // <use xlinkHref={src}></use>
  // <svg class="fill_default" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" style={{background:fill, fill:fill}}>
  //
  //   <image width="100" height="100" xlinkHref={src} class="imageReference" style={{color:fill}}></image>
  // </svg>

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   let objSvgs = document.getElementsByClassName('objectReference');
  //   for(let i = 0; i < objSvgs.length; i++){
  //     let obj = objSvgs[i];
  //     let svg = obj.contentDocument;
  //     console.log(svg);
  //     // if(svg){
  //     //   svg.stlye.fill = "lime";
  //     // }
  //   }
  // });

  const handleLoad = () => {
    let objSvgs = document.getElementsByClassName('objectReference');
    for(let i = 0; i < objSvgs.length; i++){
      let obj = objSvgs[i];
    }
  };

  //<object type="image/svg+xml" data={src} viewBox="0 0 100 100" style={{fill:fill}} class="objectReference" >
  //   <image width="100" height="100" xlinkHref={src} class="imageReference" ></image>
  // </object>

  // <use xlinkHref={src}></use>

  // <svg class="fill_default" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" style={{background:fill, fill:fill}}>
  //
  //   <image width="100" height="100" xlinkHref={src} class="imageReference" style={{color:fill}}></image>
  // </svg>

  // <a href={href} target="_blank">
  //   <svg class="fill_default" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet" style={{fill:fill}}>
  //     <image width="100" height="100" xlinkHref={src} class="imageReference" style={{color:fill}}/>
  //   </svg>
  // </a>

  // <a href={href} target="_blank">
  //   <div class='divReference' style={{ background:fill, maskImage:`url(${src})`, webkitMaskImage:`url(${src})`}}></div>
  // </a>

  // if (platform == 'instagram') {
  //   src = 'https://cdn-icons-png.flaticon.com/512/87/87390.png';
  // }else if (platform == 'tiktok') {
  //   src = 'https://cdn-icons-png.flaticon.com/512/1946/1946552.png';
  // }

  let classNames = "SocialButton mainHover "+ type;
  return (
    <div className={classNames} style={{'--social_color':fill}}>

      <a data-link={link_id} href={href} target="_blank" onClick={onClick}>
        <svg id="a" class="" viewBox="-1.5 -1.5 103 103" preserveAspectRatio="xMidYMid meet">

          <use href={src}></use>

          <defs>
            <filter id="bevel-filter">
              <feFlood flood-color="#fff9" result="light"/>
              <feFlood flood-color="#000d" result="dark"/>

              <feComposite operator="out" in="light" in2="SourceGraphic" result="glow"/>
              <feComposite operator="out" in="dark" in2="SourceGraphic" result="shadow"/>

              <feOffset dx="2" dy="3" in="glow" result="offsetblur1"/>
              <feOffset dx="-3" dy="-4" in="shadow" result="offsetblur2"/>
              <feGaussianBlur stdDeviation="3" in="offsetblur1" result="offsetblur1" />
              <feGaussianBlur stdDeviation="3" in="offsetblur2" result="offsetblur2" />

              <feComposite operator="atop" in="offsetblur1" in2="SourceGraphic" out="bevel1"/>
              <feComposite operator="atop" in="offsetblur2" in2="bevel1"/>
            </filter>


            <linearGradient id="twisted-metal" gradientTransform="rotate(75)">
              <stop offset="0%" stop-color={fill} />
              <stop offset="50%" stop-color="#4A5059" />
              <stop offset="100%" stop-color={fill} />
            </linearGradient>
          </defs>
        </svg>
      </a>

    </div>
  );
};

export default SocialButton;
