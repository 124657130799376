import styles from './About.css';

import React, {useEffect, useState } from 'react';


const About = ({}) => {

  document.title = "Why our mission is to increase revenue for online businesses"
  document.querySelector('meta[name="description"]').setAttribute("content", "Putting your best side on display to your customers is not always easy. That's where we come in. We offer a suite of tools to show off to your leads in the best way.");

  // As business owners and innovators ourselves, we're more than just sofware providers;
  // we're consultants and partners in growing your brand.
  // <br/><br/>
  // We understand some of the pitfalls in starting a business for the first time,
  // so we want to make it easier for others to enter into their entrepreneurial potential.
  // <br/><br/>
  // How do we assist you on your journey?
  // We pride ourselves on building great technology.
  // At the moment, we have a limited number of simple,
  // but effective tools for your business marketing.
  // And that list is constantly expanding!
  // <br/><br/>
  // If you are a self-starter, ready to supercharge your journey,
  // reach out to us and we'd love to see how we can help.

  let content = [
    <p>We're not just software providers—we're business owners and innovators like you. We're here to help grow your brand, acting as your consultants and partners.</p>,
    <p>Starting a business can be tricky at first, and we've been there too. That's why we're dedicated to making the journey easier for budding entrepreneurs.</p>,
    <p>How do we support you? We focus on crafting top-notch technology. Right now, we offer a few simple yet powerful tools for your business marketing, and we're always adding more!</p>,
    <p>If you're eager to take charge of your journey or just want to learn more about what we offer, join our waitlist. We'd be thrilled to lend a hand and see how we can support you.</p>
  ]

  content =[
    <h2>Does this strike too close to home?</h2>,
    <p>You've decided to finally get serious about your social media page for your small business.<br/>
    For weeks, you stay consistent.<br/>
    You post regularly.<br/>
    You're even coming up with new ideas every week that have never been done before - and mixing in some trending audios too.<br/>
    But at the end of the month - you only have a few new followers and no new customers to show for your hard work.</p>,
    <p>I've been there and it sucks. Burnout ensued and I gave up on a hobby I really enjoyed for a while, wondering why I couldn't make something that people wanted.</p>,
    <p>The thing is, it was never a problem with my products - I had a communication issue.
    I was unable to properly explain the value I had to the people I was able to reach. And to the people who were curious, I did not have a proper outlet for them.</p>,
    <p style={{fontWeight:'bold'}}>If this sounds familiar to you or someone you know, then you are precisely who I am trying to reach</p>,
    <p>In the past months, I've been building this website to alleviate a lot of the hardship people face with similar strategies.
    Software can only get you so far though. There is quite a bit of human ingenuity required to compete in today's vast online market.
    That is why I've also been studying content, marketing strategies, and communication in general to build templates and plans for growing small businesses.</p>,
    <p>If you want to build a business that truly creates value instead of trading your time for money at a job you hate - send me an <a href="mailto:justin@epicenters.io" style={{textDecoration:'underline'}}>email</a>.
    Whether you could find benefit from a website, a tailored marketing plan, or more products that provide value to your followers, we'll find how to best assist you in your business' growth.</p>
  ]

  return (
    <div className="About">

      <div style={{margin:"3em auto 0 auto", textAlign:'left', width:'80%', fontSize:'1.25em'}}>

        {content}

      </div>

    </div>
  )
};

export default About;
