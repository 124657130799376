import styles from './Analytics.css';
import StackedLine from '../charts/StackedLine';
import React, { useRef, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams } from "react-router";
import * as d3 from "d3";


const Analytics = () => {


  const [analytics, setAnalytics] = useState({
          "domain": [
              "2023-05-31",
              "2023-06-30"
          ],
          "unique_visitors": 4,
          "page_views": 13,
          "link_clicks": 4,
          "redirects": 4,
          "subscribers": 4,
          "audience":[],
          "clicks_by_day": {
              "QP7FN3R6NWLFXMXY": {
                  "2023-06-30": 1
              },
              "KGDM36XVNVKJMVRV": {
                  "2023-06-28": 2,
                  "2023-06-30": 1
              },
              "other": {}
          },
          "views_by_day": {
              "2023-06-28": 10,
              "2023-06-30": 3
          }
      });

  const fetchAnalytics = () => {
    fetch(process.env.REACT_APP_API_URL + '/analytics', {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
    .then(data => data.json())
    .then(data => {
      console.log(data);
      setAnalytics(data);
    })
  }

  useEffect(() => {
    fetchAnalytics();
  },[]);

  return (
    <div className="Analytics">

      <div class="dashCard span2">
        <div>Link Clicks</div>
        <StackedLine data={analytics['clicks_by_day']} domain={analytics['domain']} background="none" />
      </div>
      <div class="dashCard span1" style={{display:'block', textAlign:'left', alignItems:'left', fontSize:'1.5em', padding:'.5em'}}>
        unique visitors: {analytics['unique_visitors']} <br/>
        page views: {analytics['page_views']} <br/>
        subscribers: {analytics['subscribers']} <br/>
        link clicks: {analytics['link_clicks']} <br/>
        redirects: {analytics['redirects']} <br/>
      </div>
      <div class="dashCard span1" style={{display:'block', textAlign:'left', alignItems:'left', fontSize:'1em', padding:'.5em'}}>
        {analytics['audience'].slice(0, 5).map((member)=>{
          return <>{member['email']}<br/></>
        })}
      </div>
      <div class="dashCard span2">
        <div>Page Visits</div>
        <StackedLine data={{0:analytics['views_by_day']}} domain={analytics['domain']} background="none" showKey={false}/>
      </div>



    </div>
  );
};

export default Analytics;
