import React from 'react';
import styles from './ContactIcon.css'

const ContactIcon = ({ url, img, downloadable=true }) => {
  return (
    <div className="ContactIcon mainHover">
      <div class="icon">
          <img src={img} alt='' />
        </div>
        
        {downloadable ? <>
              <span class="contactText">Download Contact</span>
              <span class="plus">+</span>
              </> : <></>}
    </div>
  );
};

export default ContactIcon;
