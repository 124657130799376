import React, {useEffect, useState } from 'react';


const TextContent = ({ content='privacy-policy'}) => {

  let body = [];
  if(content == 'privacy-policy'){
    body = [
          <h1>Privacy Policy</h1>,
          `Last Modified: March 26, 2024`,
          <h3>Cookies or Browser Cookies</h3>,
          `We use cookies to improve site experience like being able to show you a list of promo codes from your liked creators without loggin in. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies`,
          <h3>Information We Collect</h3>,
          `Users may choose to submit their email to receive promotional material and information from users on the platform. Users may also choose to opt out of messaging at any time. This information is completely optional to use the site and is only used if users explicitly opt in.`
        ]
  }else if(content == 'terms-and-conditions'){
    body = []
  }

  return (
    <div className="TextContent">

      <div style={{margin:"7em auto 0 auto", textAlign:'left', width:'80%'}}>
        {body}
      </div>

    </div>
  )
};

export default TextContent;
