// import styles from './PageEditor.css';

import MultiSelect from '../forms/MultiSelect';
import Select from '../forms/Select';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Toggle from '../forms/Toggle';
import Number from '../forms/Number';
import ColorPicker from '../forms/ColorPicker';

import React, {useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams, useOutletContext } from "react-router";


const PageEditor = () => {
  let { id } = useParams();
  const addToastMessage = useOutletContext();

  const [page, setPage] = useState({
    'name':'',
    'primary_color':'',
    'secondary_color':'',
    'tertiary_color':'',
    'quarnary_color':'',
    'layout_type':'',
    'collect_email':'',
    'download_contact':''
  });

  const [pageAttributes, setPageAttributes] = useState({});
  const [pageLinks, setPageLinks] = useState({1:[], 2:[]});

  const [tableOptions, setTableOptions] = useState({});



  let homeDir = '';



  const fetchOptions = (tableName) =>{
    fetch(process.env.REACT_APP_API_URL + '/' + tableName, {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if(tableName == 'links'){
        data.forEach((item, i) => {
          item['name'] = item['title'];
        });

      }
      setTableOptions(prevState => ({ ...prevState, [tableName]: data}));
    });
  }

  const fetchCurrentValues = () =>{
    // if we do not create a new record, then we need to render inital values of each input
    fetch(process.env.REACT_APP_API_URL + '/pages/' + id, {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setPage(data)
        setPageAttributes(data['custom_attributes'])
      });

    fetch(process.env.REACT_APP_API_URL + '/page_links', {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
    .then((response) => response.json())
    .then((data) => {
      let newLinks = {1:[], 2:[]};
      for (let i = 0; i < data.length; i++) {
        if(data[i]['page_id'] == id && [1,2].includes(data[i]['section'])){
          newLinks[data[i]['section']].push(data[i]['link_id'])
        }
      }
      setPageLinks( newLinks )
    });

    // fetch(process.env.REACT_APP_API_URL + '/page_links', {
    //   headers: {
    //     'Authorization': Cookies.get('Authorization')
    //   }
    // })
    // .then((response) => response.json())
    // .then((data) => {
    //   let newLinks = {1:[], 2:[]};
    //   for (let i = 0; i < data.length; i++) {
    //     if(data[i]['page_id'] == id && [1,2].includes(data[i]['section'])){
    //       newLinks[data[i]['section']].push(data[i]['link_id'])
    //     }
    //   }
    //   setPageLinks( newLinks )
    // });

  }

  useEffect(() => {
    // fetchDefinition();
    if(id != 'add'){
      fetchCurrentValues();
    }
    fetchOptions('links');

    fetch(process.env.REACT_APP_API_URL + '/page/define', {
      headers: {
        'Authorization': Cookies.get('Authorization')
      }
    })
    .then((response) => response.json())
    .then((data) => {
      let enums = ['layout_type', 'socials_style', 'card_type', 'cta'];
      for (let enum_idx in enums){
        let types = []; //data['layout_type']['options'];
        for (let id in data[enums[enum_idx]]['options']){
          types.push({id:id})
        }
        setTableOptions(prevState => ({ ...prevState, [enums[enum_idx]]: types }));
      }
    });



  },[id]);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setPage({...page, [name]:value})
  }

  const handleLinkChange = (event, idx, section=1) => {
    let { name, value } = event.target;
    setPageLinks( prevState => {
      let newArr = pageLinks[section];
      newArr[idx] = value;
      return {...prevState, [section]:newArr}
    })
    // pageLinks[section][idx] = value;
    // console.log(pageLinks)
  }

  const handleSubmit = (event) => {
    let params = {};

    let url, method;
    if(id == 'add'){
      url = '/pages/';
      method = 'POST';
    }else{
      url = '/pages/' + id
      method = 'PUT';
    }

    fetch(process.env.REACT_APP_API_URL + url, {
      method: method,
      body: JSON.stringify(page),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Cookies.get('Authorization')
      }
    })
      .then(response => response.json())
      .then(data => {
        id = data['id']
        addToastMessage({type:'Success', message:'Success', detail:'Data Saved'});
      })
      .catch(error => console.error(error))
      .then((data) => {
        let newLinks = pageLinks[1].map((i)=>{return [i,1]}).concat( pageLinks[2].map((i)=>{return [i,2]}) );
        // console.log(newLinks)

        fetch(process.env.REACT_APP_API_URL + '/set_page_links/' + id , {
          method: 'POST',
          body: JSON.stringify({'links':newLinks}),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Cookies.get('Authorization')
          }
        })

        fetch(process.env.REACT_APP_API_URL + '/set_page_attributes/' + id , {
          method: 'POST',
          body: JSON.stringify({'attributes':pageAttributes}),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Cookies.get('Authorization')
          }
        })
      });
  }


  return (
    <div className="PageEditor">

        <h1>Page Editor</h1>
        <div style={{background:'white', height:'1px', width:'90%', margin:'.5em auto .5em auto'}}></div>


        <div style={{display:"grid", gridTemplateColumns:'1fr', margin:'0 auto', width:'fit-content', justifyItems:'start'}}>
          <Input name='name' label='name' value={page['name']} onChange={handleInputChange}/>
          <ColorPicker name='primary_color' label='primary_color' value={page['primary_color']} onChange={handleInputChange}/>
          <ColorPicker name='secondary_color' label='secondary_color' value={page['secondary_color']} onChange={handleInputChange}/>
          <ColorPicker name='tertiary_color' label='tertiary_color' value={page['tertiary_color']} onChange={handleInputChange}/>
          <ColorPicker name='quarnary_color' label='quarnary_color' value={page['quarnary_color']} onChange={handleInputChange}/>
          <Select name='layout_type' label='layout_type' value={page['layout_type']} options={tableOptions['layout_type']} onChange={handleInputChange}/>
          <Select name='socials_style' label='socials_style' value={page['socials_style']} options={tableOptions['socials_style']} onChange={handleInputChange}/>
          <Select name='card_type' label='card_type' value={page['card_type']} options={tableOptions['card_type']} onChange={handleInputChange}/>
          <Toggle name='collect_email' label='collect_email' value={page['collect_email']} onChange={handleInputChange}/>
          <Toggle name='download_contact' label='download_contact' value={page['download_contact']} onChange={handleInputChange}/>
          <Input name='subscribe_copy' label='subscribe_copy' value={page['subscribe_copy']} onChange={handleInputChange}/>
          <Select name='cta' label='cta' value={page['cta']} options={tableOptions['cta']} onChange={handleInputChange}/>
        </div>



        <h2>Custom Attributes</h2>
        <div style={{background:'white', height:'1px', width:'90%', margin:'.5em auto .5em auto'}}></div>


        <div style={{display:"grid", gridTemplateColumns:'fit-content(100px)', margin:'0 auto', width:'fit-content', justifyItems:'center'}}>

          {Object.entries(pageAttributes).map(([key, value], idx) =>{
            return(<>
              <Input name={key} label='Attribute' value={key} onChange={(event)=>{ setPageAttributes(current => { const {[event.target.name]:val, ...rest} = current; rest[event.target.value]= val; return rest; }) }}/>
              <Input name={key} label='Value' value={value} onChange={(event)=>{ setPageAttributes({...pageAttributes, [event.target.name]: event.target.value })}}/>
              <Button name={key} label="delete" onClick={(event) =>{ setPageAttributes(current => { const {[event.target.name]:val, ...rest} = current; return rest; }) }}/>
            </>)
          })}
          <Button label='Add Attribute' onClick={(event)=>{ setPageAttributes({...pageAttributes, ' ': ' ' })}}/>
        </div>




        <h2>section 1 (social icons)</h2>
        <div style={{background:'white', height:'1px', width:'90%', margin:'.5em auto .5em auto'}}></div>


        <div style={{display:"grid", gridTemplateColumns:'1fr 1fr', margin:'0 auto', width:'fit-content', justifyItems:'center'}}>

          {pageLinks[1].map((link_id, idx) =>{
            return(<>
              <Select name='layout_type' label={'Social ' + (idx+1) } value={link_id} options={tableOptions['links']} onChange={(event)=>{handleLinkChange(event,idx,1)}}/>
              <Button label="delete" onClick={() =>{ let l = pageLinks[1]; l.pop(idx); setPageLinks( {1:l, 2:pageLinks[2]} ) } }/>
            </>)
          })}
          <Button label='Add Social' onClick={() => {setPageLinks( {1:[...pageLinks[1], null], 2:pageLinks[2]} )}}/>

        </div>




        <h2>section 2 (links)</h2>
        <div style={{background:'white', height:'1px', width:'90%', margin:'.5em auto .5em auto'}}></div>

        <div style={{display:"grid", gridTemplateColumns:'1fr 1fr', margin:'0 auto', width:'fit-content', justifyItems:'center'}}>

          {pageLinks[2].map((link_id, idx) =>{
            return(<>
              <Select name='layout_type' label={'Link ' + (idx+1) } value={link_id} options={tableOptions['links']} onChange={(event)=>{handleLinkChange(event,idx,2)}}/>
              <Button label="delete" onClick={() =>{ let l = pageLinks[2]; l.pop(idx); setPageLinks( {1:pageLinks[1], 2:l} ) } }/>
            </>)
          })}
          <Button label='Add Link' onClick={() => {setPageLinks( {1:pageLinks[1], 2:[...pageLinks[2], null]} )}}/>

        </div>


        <Button onClick={handleSubmit} label="Submit" color='#0783e4ff'/>
    </div>
  );
};

export default PageEditor;
