import styles from './StackedLine.css';
import React, { useRef, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useParams } from "react-router";
import * as d3 from "d3";


function convertObjToArr(obj){
  let arr = [];

  Object.keys(obj).forEach((key, i) => {
    let set = obj[key]
    let newSet = []
    Object.keys(obj[key]).forEach((x, j) => {
      let y = obj[key][x];
      newSet.push([x, y])
    })
    arr.push(newSet);
  });

  return arr;
}
function compareX(a, b){
  if(a[0] == b[0]){
    return 0
  }else if(a[0] > b[0]){
    return 1
  }else{
    return -1
  }
}


const StackedLine = ({
    //can accept an array or object containing sets of points - the sets should be in order to display, bottom to top
    data = {0: {34:23, 40:50, 43:52, 56:23}, 1: {34:23, 40:42, 43:40, 56:51}},
    lineColors = ['#f75c57','#f7bc57','#f7f757','#57f75a','#57eff7','#575af7','#bf57f7','#f757f2'].sort((a, b) => 0.5 - Math.random()), //colors from color space where Saturation = 65% and Value = 97%
    guideColor = '#8a8a99',
    background = '#29292e',
    xLabel = '',
    yLabel = '',
    title = '',
    domain = null, //to set, we need two values, starting domain and ending domain
    padValues = false,
    showKey = true,
  }) => {

  let width = 1000;
  let height = 600;
  let marginTop = 20;
  let marginRight = 20;
  let marginBottom = 40;
  let marginLeft = 40;
  let isDate = false;

  // we list out all keys to our data so that we can display key if "showKey"
  let keys = (data instanceof Object) ? Object.keys(data) : [...Array(data.length).keys()];
  
  //convert object data to array data so we only consider 1 data input type
  data = (data instanceof Object) ? convertObjToArr(data) : data;

  let x;
  //if domain is explicitly set by user
  if(domain){
    if(typeof domain[0] === 'string'){
      isDate = true;
      x = d3.scaleTime([new Date(domain[0]), new Date(domain[1])], [marginLeft, width - marginRight]);
    }else{
      x = d3.scaleLinear(domain, [marginLeft, width - marginRight]);
    }
  }else{ // we have to figure out our domain ourselves
    domain = [null, null]
    data.forEach((set, i) => {
      set.forEach((point, j) => {
        domain[0] = (domain[0] == null || domain[0] > point[0]) ? point[0] : domain[0]
        domain[1] = (domain[1] == null || domain[1] < point[0]) ? point[0] : domain[1]
      })
    });
    x = d3.scaleLinear(domain, [marginLeft, width - marginRight]);
  }

  let transformedData = [];
  let range = [null, null]
  //figure out our range

  let dateIter = (i) => {
    var date = new Date(domain[0]);
    date.setDate(date.getDate() + i);
    return date
  }

  let datConvert = (isDate) ? (i) => (new Date(i)) : (i) => (i); //takes data point and converts it into the correct output (converts to date if necessary)
  let revDatConvert = (isDate) ? (i) => (i.toISOString().slice(0,10)) : (i) => (i);  //takes a potentially converted data point and switches it back to the original input (string if date)
  let iter = (isDate) ? dateIter : (i) => (i + parseInt(domain[0]));   //function to iterate through all discrete values between domain[0] and domain[1]
  let endStop = (isDate) ? new Date(domain[1]) : domain[1];  //figures out the end condition from the above function

  // I'm so sorry future Justin... this is confusing af. To account for ints and date ranges, I'm passing down different functions to keep the same core loop

  //for each data "set" in our data - and an iterator i
  data.forEach((set, i) => {
  // for(let i = 0; iter(i - 1) < new Date(domain[1]); i++) {
    //if we have data in this set
    set.sort(compareX)
    if(set.length > 0){
      let newSet = []
      // set.forEach((point, j) => {

      //we have an iterator setIndex to tell us which point is next in the data and if we can end calculation for this step early
      let setIndex = 0;

      //for each point in the domain
      for(let j = 0; iter(j - 1) < endStop; j++) {
        
        //our y will either be 0 if this is our first set, or the point from the last set
        let y = (i == 0) ? 0 : transformedData[i-1][j][1];//point[1]
        
        //if we are not at the end of our current data set AND our set x value matches the x value we are on
        if(setIndex < set.length && set[setIndex][0] == revDatConvert(iter(j))){
          y += set[setIndex][1];
          setIndex ++
        }
        newSet.push([iter(j), y])

        // see if we need to expand the upper or lower bound of our range
        range[0] = (range[0] == null || range[0] > y) ? y : range[0]
        range[1] = (range[1] == null || range[1] < y) ? y + 1 : range[1]
      }
      transformedData.push(newSet);
    }
  });
  console.log(transformedData);

  // const y = d3.scaleLinear(d3.extent(data), [height - marginBottom, marginTop]);
  const y = d3.scaleLinear(range, [height - marginBottom, marginTop]);
  // const line = d3.line((d, i) => x(i), (d, i) => y(d));

  let line = d3.line((d, i) => x(d[0]), (d, i) => y(d[1]));;
  if(isDate){
    line = d3.line((d, i) => x(d[0]), (d, i) => y(d[1]));
  }

  const gx = useRef();
  const gy = useRef();

  useEffect(() => void d3.select(gx.current)
                            .call(d3.axisBottom(x).ticks(10))
                            .call(g => g.selectAll(".tick text")
                              .attr("dy", '1em')
                              .attr("font-size", '1.5em'))
                            .call(g => g.selectAll(".domain").attr("stroke", guideColor))
                            .call(g => g.selectAll(".tick line").attr("stroke", guideColor))
                              .attr("stroke", guideColor), [gx, x]);

  useEffect(() => void d3.select(gy.current)
                            .call(d3.axisLeft(y).ticks(7))
                            .call(g => g.selectAll(".tick text")
                              .attr("font-size", '2em'))
                            .call(g => g.select(".domain").remove())
                              .attr("stroke", guideColor)
                            .call(g => g.selectAll(".tick line")
                              .attr("stroke", guideColor))
                            .call(g => g.selectAll(".tick line").clone()
                              .attr("x2", width - marginLeft - marginRight)
                              .attr("stroke-opacity", 0.1)
                              .attr("stroke", guideColor)), [gy, y]);

  return (
    <div className="StackedLine" style={{height:"100%", width:"100%", display:"flex", alignItems:"center"}}>

        <div class="key" style={{display: showKey ? 'block' : 'none'}}>
          {keys.map((key, i) => (<div>{key} : <span class="keyColor" style={{background:lineColors[i]}}> </span></div>))}
        </div>

        <div style={{background:background, width:"90%", height:"90%", margin:"auto", display:"block"}}>
          <svg viewBox="0 0 1000 600" style={{maxHeight:"100%"}}>

            <defs>
              {transformedData.map((set, i) => (
                  <linearGradient id={"Gradient"+i} x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stop-color={lineColors[i]} />
                    <stop offset="100%" stop-color="#00000000" />
                  </linearGradient>
              ))}
            </defs>

            <g ref={gx} transform={`translate(0,${height - marginBottom})`} />
            <g ref={gy} transform={`translate(${marginLeft},0)`} />

            {transformedData.map((set, i) => (
              <g>
                <path class="line" style={{'--line-color':lineColors[i]}} stroke-width="3" d={line(set)} />
                <g fill={lineColors[i]} stroke={lineColors[i]} stroke-width="3">
                  {set.map((d, i) => (<circle key={i} cx={x(d[0])} cy={y(d[1])} r="4" />))}
                </g>
              </g>
            ))}

          </svg>
        </div>

    </div>
  );
};

export default StackedLine;
