import QRCode from 'qrcode-generator';

export function encodeQR(text, redundancy){
    const qr = QRCode(0, redundancy);
    qr.addData(text);
    qr.make();

    const qrSize = qr.getModuleCount();
    let dataMatrix = [];

    for (let i = 0; i < qrSize; i++) {
      let row = [];
      for (let j = 0; j < qrSize; j++) {
        row.push( qr.isDark(i, j) );
      }
      dataMatrix.push(row);
    }
    return dataMatrix;
  }

export async function downloadContactCard(profile_id, fileName='contact'){

	//get vcard file of specified profile_id from rails api
	const response = await fetch(process.env.REACT_APP_API_URL + '/vcard/' + profile_id,
		{
			method: 'GET',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
			}
		});

	//set response as a file (blob)
	const blob = await response.blob();

	//create a url to reference this downloaded file
	const url = window.URL.createObjectURL(blob);

	//create an anchor tag with this url
	const link = document.createElement('a');
	link.href = url;

	//set name of file (download attribute)
	link.setAttribute('download', `${fileName}.vcf`);
	document.body.appendChild(link);

	//click link to trigger the file download
	link.click();
}
