import React from 'react';
import './FrankenCard.css'

import Card from '../cards/Card'

const FrankenCard = ({  }) => {
  let card_type = `default`;

  let title = 'Instagram';
  let text = 'Come follow my miniature making journey';
  let card_img = 'https://epicenter-profile-data.s3.us-west-1.amazonaws.com/profile_1/84a4c829-1311-4a55-a940-18952cc18530?response-content-disposition=attachment%3B%20filename%3D%22duck.svg%22%3B%20filename%2A%3DUTF-8%27%27duck.svg&response-content-type=image%2Fsvg%2Bxml&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3FLDZFDP5Q4HEEGW%2F20241101%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20241101T202449Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=889f4d3a5aef11b861aee00d86807c0212113359b3d567b3861aa4bbbf2f95ac';

  let theme = {}

  let cardTypes = ['default','glide','block','fade']
  let cardType = [cardTypes[1]]

  return (
    <div className='FrankenCard'>
      <div>Card Type</div>
      <div>All</div>
      <div>Primary</div>
      <div>Title,Text</div>
      <div>Title,Image</div>
      <div>Image,Text</div>
      <div>Title</div>
      <div>Text</div>
      <div>Image</div>
      <div>None</div>
      {cardType.map((item, i) => {
        return <>
          <div>{item}</div>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} title={title} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} type={'Primary'} title={title} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} title={title} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} title={title} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} title={title} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} text={text} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} img={card_img} className={item}/>
          <Card key={1} link_id={'./'} theme={theme} className={item}/>
        </>
      })}
    </div>
  );
};

export default FrankenCard;
